export default function resetHook(state, clearTable) {
    // 菜单改变
    function resetFunc() {
        // 清空数据
        clearTable()
        // 重置条件
        state.query.pageNum = 1;
        state.query.province = [];//省份
        state.query.city = [];//城市
        state.query.ratioType = "环比";
        state.query.mainType = [];//餐饮类型
        state.query.cuisineType = [];//菜系类型
        state.query.cuisine = [];//菜系
        state.query.shop_num_min = null; //门店数
        state.query.shop_num_max = null;
        state.query.shop_inc_ratio_min = null; //门店增长率
        state.query.shop_inc_ratio_max = null;
        state.query.city_num_min = null; //城市数
        state.query.city_num_max = null;
        state.query.city_inc_ratio_min = null; //城市增长率
        state.query.city_inc_ratio_max = null;
        state.query.avg_shop_age_min = null; //平均店龄
        state.query.avg_shop_age_max = null;
        state.query.shop_close_ratio_min = null; //关店率
        state.query.shop_close_ratio_max = null;
        state.query.center_ratio_min = null; //集中度
        state.query.center_ratio_max = null;
        state.query.tier_new_ratio_min = null; //1-3线新店占比
        state.query.tier_new_ratio_max = null;
        state.query.tier_ratio_min = null; //1-3线城市门店数占比
        state.query.tier_ratio_max = null;
    }
    return {
        resetFunc
    }
}