import { nextTick, reactive, ref } from "vue";
import { getBrandList, getBrandRegion, getBrandTop, getBrandTrend } from "@/request/api";
import { ElLoading } from 'element-plus';

export default (indexState, getList, getWeightConfig) => {
    const regionTable = ref();
    const state = reactive({
        // 评分信息
        tableScore: {
            query: {
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            tableData: [],
        },
        // 地域分布
        tableRegion: {
            query: {
                // maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
        // 季度趋势
        tableTrend: {
            query: {
                // maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
        // 季度Top
        tableTop: {
            query: {
                // maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
    })

    // 获取列表-评分信息
    async function getListScore() {
        const params = { ...indexState.query, ...state.tableScore.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getBrandList(params);
        state.tableScore.total = data?.total || 0;
        state.tableScore.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 获取列表-地域分布
    async function getListRegion() {
        const params = { ...indexState.query, ...state.tableRegion.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getBrandRegion(params);
        state.tableRegion.total = data?.total || 0;
        state.tableRegion.titleData = data?.title || [];
        state.tableRegion.tableData = data?.list || [];
        nextTick(() => {
            regionTable.value.doLayout();
        })
        loadingInstance.close()
    }

    // 获取列表-季度Top
    async function getListTop() {
        const params = { ...indexState.query, ...state.tableTop.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getBrandTop(params);
        state.tableTop.total = data?.total || 0;
        state.tableTop.titleData = data?.title || [];
        state.tableTop.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 获取列表-季度趋势
    async function getListTrend() {
        const params = { ...indexState.query, ...state.tableTrend.query, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await getBrandTrend(params);
        state.tableTrend.total = data?.total || 0;
        state.tableTrend.titleData = data?.title || [];
        state.tableTrend.tableData = data?.list || [];
        loadingInstance.close()
    }

    // 分页
    function pageChange() {
        getList();
    }

    // 搜索
    function search() {
        switch (indexState.tableActive) {
            case 'score':
                state.tableScore.query.pageNum = 1;
                state.tableScore.total = 0;
                state.tableScore.tableData = [];
                break;
            case 'region':
                state.tableRegion.query.pageNum = 1;
                state.tableRegion.total = 0;
                state.tableRegion.tableData = [];
                break;
            case 'trend':
                state.tableTrend.query.pageNum = 1;
                state.tableTrend.total = 0;
                state.tableTrend.tableData = [];
                break;
            case 'top':
                state.tableTop.query.pageNum = 1;
                state.tableTop.total = 0;
                state.tableTop.tableData = [];
                break;
        }
        getList();
    }

    // 清空所有table/页码重置
    function clearTable() {
        state.tableScore.query.pageNum = 1;
        state.tableScore.total = 0;
        state.tableScore.tableData = [];
        state.tableRegion.query.pageNum = 1;
        state.tableRegion.total = 0;
        state.tableRegion.tableData = [];
        state.tableTrend.query.pageNum = 1;
        state.tableTrend.total = 0;
        state.tableTrend.tableData = [];
        state.tableTop.query.pageNum = 1;
        state.tableTop.total = 0;
        state.tableTop.tableData = [];
    }
    return { state, regionTable, search, clearTable, getListScore, getListRegion, getListTrend, getListTop, pageChange }
}