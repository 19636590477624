import { reactive, onBeforeMount } from "vue";
import { getBrandDict } from '@/request/api';
import { ElLoading } from 'element-plus';

// 参数选项
export default function paramsListHook({ query }) {
    const paramsListState = reactive({
        batchList: [],// 批次
        provinceList: [],// 省份
        cityList: [],// 城市
        ratioTypeList: ['同比', '环比'],// 比率
        cuisineList: [],// 餐饮类型-菜系1级
        cuisine1List: [],// 菜系类型-菜系2级
        cuisineTypeList: [],// 菜系-菜系3级
    })
    // 获取批次
    async function getBatch() {
        const loadingInstance = ElLoading.service()
        const { data } = await getBrandDict({ code: 'batch' })
        paramsListState.batchList = data || [];
        query.batch = paramsListState.batchList[0];
        setTimeout(() => { loadingInstance.close() }, 200)
    }
    // 获取省份
    async function getProvince() {
        const { data } = await getBrandDict({ code: 'province' })
        paramsListState.provinceList = data || [];
    }
    // 获取城市
    async function getCity() {
        const params = { code: 'city', province: query.province }
        const { data } = await getBrandDict(params)
        paramsListState.cityList = data || [];
    }
    // 获取餐饮类型-菜系一级
    async function getCuisine() {
        const { data } = await getBrandDict({ code: 'cuisine' })
        paramsListState.cuisineList = data || []
    }
    // 获取菜系类型-菜系二级
    async function getCuisine1() {
        const { data } = await getBrandDict({ code: 'cuisine1', cuisine1: query.mainType })
        paramsListState.cuisine1List = data || []
    }
    // 获取菜系-菜系三级
    async function getCuisine2() {
        const params = { code: 'cuisine2', cuisine1: query.mainType, cuisine2: query.cuisineType }
        const { data } = await getBrandDict(params)
        paramsListState.cuisineTypeList = data || []
    }
    // 省份改变
    function changeProvince() {
        paramsListState.cityList = [];//清空城市选项
        query.city = [];//清空已选城市
        getCity()//获取城市
    }
    // 餐饮类型改变
    function changeMainType() {
        query.cuisineType = [];//清空菜系类型
        query.cuisine = [];//清空菜系已选
        getCuisine1();//获取菜系
        getCuisine2();//获取菜系明细
    }
    // 菜系类型改变
    function changeCuisineType() {
        query.cuisine = [];//清空菜系已选
        getCuisine2();//获取菜系明细
    }
    onBeforeMount(() => {
        // 获取条件选项
        getBatch();
        getProvince(); //省份
        getCity(); //城市
        getCuisine()// 餐饮类型-菜系1级
        getCuisine1();// 餐饮类型-菜系2级
        getCuisine2();// 餐饮类型-菜系3级
    });
    return { paramsListState, changeProvince, changeMainType, changeCuisineType }
}