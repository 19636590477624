import { onBeforeMount, reactive } from 'vue';
import { getStorage, setStorage } from '@/utils';

export default () => {
    // 配置默认值
    const configDefult = {
        weight_shop_num: 26,//门店排序得分
        weight_city_num: 12,//城市排序得分
        weight_tier_ratio: 9,//1-3三线城市门店数占比排序得分
        weight_center_ratio: 3,//集中度排序得分
        weight_shop_inc_ratio: 12,//门店增长率排序得分
        weight_province_inc_ratio: 6,//省份增长率排序得分
        weight_city_inc_ratio: 5,//城市增长率排序得分
        weight_tier_ratio_score: 4,//1-3线新店占比排序得分
        weight_avg_shop_age: 12,//平均店龄排序得分
        weight_shop_close_ratio: 11,//关店率排序得分
    }
    const state = reactive({
        show: false,
    })

    // 打开弹框
    function open() {
        setDefualt()
        state.show = true;
    }

    // 关闭弹框
    function close() {
        state.show = false;
    }

    // 获取缓存的值，如果不存在，设置默认值
    function setDefualt() {
        const weightConfig = getStorage('weightConfigBrand');
        if (!weightConfig) {
            setStorageConfig(configDefult)
        }
    }

    // 将配置表单缓存到本地
    function setStorageConfig(form) {
        setStorage('weightConfigBrand', JSON.stringify(form))
    }

    // 恢复默认值
    function reDefualt() {
        setStorageConfig(configDefult)
    }

    // 获取配置对象
    function getWeightConfig() {
        return JSON.parse(getStorage('weightConfigBrand'))
    }

    onBeforeMount(() => {
        setDefualt()
    })

    return { state, open, close, reDefualt, getWeightConfig, setStorageConfig }
}