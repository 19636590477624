<template>
  <div class="home" v-loading="state.loading">
    <!-- 头部 -->
    <my-header @outLoginFun='outLoginFun' @weightOpen="weightOpen"></my-header>
    <!-- 数据范围筛选 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="数据范围筛选"></small-title>
      </div>
      <div class="search_box">
        <div class="li">
          <h6>批次：</h6>
          <el-select v-model="state.query.batch" class="m-2" placeholder="全部" filterable>
            <el-option v-for="item in paramsListState.batchList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>省份：</h6>
          <el-select v-model="state.query.province" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeProvince">
            <el-option v-for="(item, index) in paramsListState.provinceList" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>城市：</h6>
          <el-select v-model="state.query.city" class="m-2" placeholder="全部" filterable clearable multiple collapse-tags>
            <el-option v-for="item in paramsListState.cityList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>比率：</h6>
          <el-select v-model="state.query.ratioType" class="m-2" placeholder="全部">
            <el-option v-for="item in paramsListState.ratioTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>餐饮类型：</h6>
          <el-select v-model="state.query.mainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeMainType">
            <el-option v-for="item in paramsListState.cuisineList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>菜系类型：</h6>
          <el-select v-model="state.query.cuisineType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags @change="changeCuisineType">
            <el-option v-for="item in paramsListState.cuisine1List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>菜系：</h6>
          <el-select v-model="state.query.cuisine" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags>
            <el-option v-for="item in paramsListState.cuisineTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 计算结果筛选 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="计算结果筛选"></small-title>
      </div>
      <div class="search_box">
        <div class="li">
          <h6>门店数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.shop_num_min"></el-input>-
            <el-input type="number" v-model="state.query.shop_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>门店增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.shop_inc_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.shop_inc_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>城市数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.city_num_min"></el-input>-
            <el-input type="number" v-model="state.query.city_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>省份增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.prov_inc_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.prov_inc_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>城市增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.city_inc_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.city_inc_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>平均店龄：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.avg_shop_age_min"></el-input>-
            <el-input type="number" v-model="state.query.avg_shop_age_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>关店率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.close_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.close_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>集中度：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.central_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.central_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>1-3线新店占比：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.new_tier_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.new_tier_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>1-3线城市门店数占比：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.tier_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.tier_ratio_max"></el-input>
          </div>
        </div>
      </div>
      <div class="btn_box">
        <el-button type="info" @click="resetFunc">重置</el-button>
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="结果"></small-title>
        <el-button type="primary" @click="exportExcel">导出excel<el-icon class="el-icon--right">
            <Right />
          </el-icon>
        </el-button>
      </div>
      <el-tabs v-model="state.tableActive" class="demo-tabs">
        <!-- 品牌评分信息 -->
        <el-tab-pane label="品牌评分信息" name="score">
          <div class="table_box">
            <el-table :data="tableState.tableScore.tableData" stripe border style="width: 100%"
              :header-cell-style="headerCellStyleHandle" :cell-style="cellStyleHandle" height="600">
              <el-table-column prop="brand_name" label="品牌" min-width="180" show-overflow-tooltip fixed="left" />
              <el-table-column prop="total_score_" label="总得分" show-overflow-tooltip fixed="left" />
              <el-table-column prop="shop_num" label="门店数" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="city_num" label="城市数" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="tier_ratio" label="1-3线城市门店数占比" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="central_ratio" label="集中度" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="shop_inc_ratio" label="门店增长率" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="prov_inc_ratio" label="省份增长率" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="city_inc_ratio" label="城市增长率" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="new_tier_ratio" label="1-3线新店占比" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="avg_shop_age" label="平均店龄" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="close_ratio" label="关店率" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="shop_num_score_" label="门店排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="city_num_score_" label="城市排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="tier_ratio_score_" label="1-3线城市门店数占比排序得分" show-overflow-tooltip min-width="120"
                align="center" />
              <el-table-column prop="center_ratio_score_" label="集中度排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="scope_score_" label="规模总得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="shop_inc_ratio_score_" label="门店增长率排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="prov_inc_ratio_score_" label="省份增长率排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="city_inc_ratio_score_" label="城市增长率排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="tier_new_ratio_score_" label="1-3线新店占比排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="growth_score_" label="成长性总得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="avg_shop_age_score_" label="平均店龄排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="close_ratio_score_" label="关店率排序得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="stability_score_" label="稳定性总得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="brand_id" label="品牌ID" show-overflow-tooltip min-width="80" align="center" />
              <el-table-column prop="batch" label="批次" show-overflow-tooltip min-width="80" align="center" />
            </el-table>
            <div class="no_data" v-if="tableState.tableScore.tableData.length === 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.tableScore.total"
                :page-size="tableState.tableScore.query.pageSize"
                v-model:current-page="tableState.tableScore.query.pageNum" @current-change="pageChange"></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 品牌地域分布 -->
        <el-tab-pane label="品牌地域分布" name="region">
          <div class="table_box">
            <el-table ref="regionTable" :data="tableState.tableRegion.tableData" stripe border style="width: 100%"
              height="600">
              <el-table-column prop="brand_name" label="品牌" min-width="180" show-overflow-tooltip fixed="left" />
              <el-table-column v-for="item in tableState.tableRegion.titleData" :prop="item" :label="item" />
            </el-table>
            <div class="no_data" v-if="tableState.tableRegion.tableData.length === 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.tableRegion.total"
                :page-size="tableState.tableRegion.query.pageSize"
                v-model:current-page="tableState.tableRegion.query.pageNum" @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 季度TOP品牌 -->
        <el-tab-pane label="季度TOP品牌" name="top">
          <div class="table_box">
            <el-table :data="tableState.tableTop.tableData" stripe border style="width: 100%" height="600">
              <el-table-column v-for="item in tableState.tableTop.titleData" :prop="item" :label="item" />
            </el-table>
            <div class="no_data" v-if="tableState.tableTop.tableData.length === 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.tableTop.total"
                :page-size="tableState.tableTop.query.pageSize" v-model:current-page="tableState.tableTop.query.pageNum"
                @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 品牌季度趋势 -->
        <el-tab-pane label="品牌季度趋势" name="trend">
          <div class="table_box">
            <el-table :data="tableState.tableTrend.tableData" stripe border style="width: 100%" height="600">
              <el-table-column prop="brand_name" label="品牌" min-width="180" show-overflow-tooltip fixed="left" />
              <el-table-column v-for="item in tableState.tableTrend.titleData" :prop="item" :label="item" />
            </el-table>
            <div class="no_data" v-if="tableState.tableTrend.tableData.length === 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.tableTrend.total"
                :page-size="tableState.tableTrend.query.pageSize"
                v-model:current-page="tableState.tableTrend.query.pageNum" @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <!-- 权重配置弹框 -->
  <weight-dialog :show="weightState.show" @close="weightClose" @reDefualt="reDefualt"
    @setStorageConfig='setStorageConfig'></weight-dialog>
</template> 

<script setup>
import MyHeader from "./MyHeader.vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import { reactive } from "vue";
import { getBrandExport } from "@/request/api";
import outLoginHook from "@/utils/outLoginHook.js";
import paramsListHook from "./paramsListHook.js";
import { ElMessage } from "element-plus";
import { baseURL } from "@/request/http.js";
import { getStorage } from "@/utils";
import { Right } from "@element-plus/icons-vue";
import tableStyleHook from "./tableStyleHook.js";
import resetHook from "./resetHook.js";
import WeightDialog from "./WeightDialog.vue";// 权重配置弹框
import useWeight from "./useWeight.js";// 权重配置逻辑
import useTableData from './useTableData';

const state = reactive({
  loading: false,
  tableActive: 'score',//table选择项
  query: {
    batch: "", //批次
    province: [], //省份
    city: [], //城市
    ratioType: "环比", //比率
    mainType: [], //餐饮类型
    cuisineType: [], //菜系类型
    cuisine: [], //菜系
    shop_num_min: null, //门店数
    shop_num_max: null,
    shop_inc_ratio_min: null, //门店增长率
    shop_inc_ratio_max: null,
    city_num_min: null, //城市数
    city_num_max: null,
    city_inc_ratio_min: null, //城市增长率
    city_inc_ratio_max: null,
    prov_inc_ratio_min: null, //省份增长率
    prov_inc_ratio_max: null,
    avg_shop_age_min: null, //平均店龄
    avg_shop_age_max: null,
    close_ratio_min: null, //关店率
    close_ratio_max: null,
    central_ratio_min: null, //集中度
    central_ratio_max: null,
    new_tier_ratio_min: null, //1-3线新店占比
    new_tier_ratio_max: null,
    tier_ratio_min: null, //1-3线城市门店数占比
    tier_ratio_max: null,
  }
})

// 权重配置
const { state: weightState, open: weightOpen, close: weightClose, reDefualt, setStorageConfig, getWeightConfig } = useWeight()

// 获取列表
function getList() {
  switch (state.tableActive) {
    case 'score':
      getListScore()
      break;
    case 'region':
      getListRegion()
      break;
    case 'trend':
      getListTrend()
      break;
    case 'top':
      getListTop()
      break;
  }
}

// 表格数据
const { state: tableState, regionTable, search, getListScore, getListRegion, getListTrend, getListTop, pageChange, clearTable } = useTableData(state, getList, getWeightConfig)

// 导出
function exportExcel() {
  let tableData = [];
  switch (state.tableActive) {
    case 'score':
      tableData = tableState.tableScore.tableData || [];
      break;
    case 'region':
      tableData = tableState.tableRegion.tableData || [];
      break;
    case 'trend':
      tableData = tableState.tableTrend.tableData || [];
      break;
    case 'top':
      tableData = tableState.tableTop.tableData || [];
      break;
  }
  if (tableData.length === 0) {
    ElMessage.error("暂无数据，请搜索");
    return;
  }
  const token = getStorage("token");
  const href = `${baseURL}${getBrandExport()}?token=${token}&code=${state.tableActive}`;
  const a = document.createElement("a");
  a.href = href;
  a.download = href;
  a.click();
}

// 重置
const { resetFunc } = resetHook(state, clearTable);

// 退出登录
const { outLoginFun } = outLoginHook();

// 条件选项
const { paramsListState, changeProvince, changeMainType, changeCuisineType } = paramsListHook(state);

// table样式
const { headerCellStyleHandle, cellStyleHandle } = tableStyleHook();

</script>

<style lang="scss" scoped>
.home {
  padding: 40px;
  padding-top: 80px;

  .row_box {
    background: #fff;
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 10px;

    .tit_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search_box {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      padding-top: 20px;

      .li {
        margin-right: 20px;
        margin-bottom: 10px;

        h6 {
          font-weight: normal;
          color: #818a9b;
          padding-bottom: 6px;
          font-size: 14px;
        }

        .el-select {
          width: 200px;
        }

        :deep(.el-input__inner) {
          border-radius: 7px;
        }

        .int_box {
          display: flex;
          align-items: center;

          .el-input {
            width: 93px;
          }
        }
      }
    }

    .btn_box {
      text-align: center;
      padding-top: 10px;
    }

    .table_box {
      position: relative;

      .no_data {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-top: 180px;
      }
    }
  }

  .page_box {
    text-align: right;
    padding: 20px 0;
  }
}

:deep(.el-table__empty-text) {
  display: none;
}

// 滚动条的宽度
:deep(.el-table__body-wrapper::-webkit-scrollbar) {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}
</style>
<style>
.el-table th {
  display: table-cell !important;
}
</style>
