<template>
    <el-dialog :model-value="show" title="权重配置" width="800px" @close="handleClose" top="20px">
        <el-form :model="state.form" label-width="220px">
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="门店排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_shop_num" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="城市排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_city_num" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="1-3三线城市门店数占比排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_tier_ratio" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="集中度排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_center_ratio" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider />
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="门店增长率排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_shop_inc_ratio" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="省份增长率排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_province_inc_ratio" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="城市增长率排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_city_inc_ratio" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="1-3线新店占比排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_tier_ratio_score" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider />
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="平均店龄排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_avg_shop_age" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="关店率排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_shop_close_ratio" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn_bar">
            <el-button @click="onReDefualt">恢复默认值</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </div>
        <div class="table_bar">
            <table>
                <thead>
                    <tr>
                        <th>KPI</th>
                        <th>大类</th>
                        <th>统计值（得分）</th>
                        <th>默认</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="10">客观性指标</td>
                        <td rowspan="4" class="back">规模</td>
                        <td>门店排序得分-每个品牌的门店数，越多得分越高</td>
                        <td>26</td>
                    </tr>
                    <tr>
                        <td>城市排序得分-每个品牌分布在几个城市（city字段），越多得分越高</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td>1-3三线城市门店数占比排序得分-每个品牌的占比越高得分越高</td>
                        <td>9</td>
                    </tr>
                    <tr>
                        <td>集中度排序得分-每个品牌的集中度值越高得分越高</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td rowspan="4" class="back">成长性</td>
                        <td>门店增长率排序得分（同比或环比，可选择）-每个品牌的增长越高得分越高</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td>省份增长率排序得分（同比或环比，可选择）-每个品牌的增长越高得分越高</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>城市增长率排序得分（同比或环比，可选择）-每个品牌的增长越高得分越高</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>1-3线新店占比排序得分-每个品牌的占比越高得分越高</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td rowspan="2" class="back">稳定性</td>
                        <td>平均店龄排序得分-每个品牌的平均店龄越大得分越高</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td>关店率排序得分-每个品牌的关店率越低得分越高</td>
                        <td>11</td>
                    </tr>
                    <tr>
                        <td colspan="3">合计</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td colspan="4" class="text_red">注意：合计必须等于100</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </el-dialog>
</template>

<script setup>
import { reactive, watch } from 'vue';
import { getStorage } from '@/utils';
import { ElMessage } from 'element-plus';

const props = defineProps({
    show: { type: Boolean, defualt: false }
})

const emits = defineEmits(['close', 'reDefualt', 'setStorageConfig'])

const state = reactive({
    form: {
        weight_shop_num: null,//门店排序得分
        weight_city_num: null,//城市排序得分
        weight_tier_ratio: null,//1-3三线城市门店数占比排序得分
        weight_center_ratio: null,//集中度排序得分
        weight_shop_inc_ratio: null,//门店增长率排序得分
        weight_province_inc_ratio: null,//省份增长率排序得分
        weight_city_inc_ratio: null,//城市增长率排序得分
        weight_tier_ratio_score: null,//1-3线新店占比排序得分
        weight_avg_shop_age: null,//平均店龄排序得分
        weight_shop_close_ratio: null,//关店率排序得分
    }
})

watch(() => props.show, (newVal) => {
    if (newVal) {
        readSetForm()
    }
})

// 读取缓存配置并给表单赋值
function readSetForm() {
    const weightConfig = getStorage('weightConfigBrand');
    state.form = JSON.parse(weightConfig)
}

// 恢复默认值
function onReDefualt() {
    emits('reDefualt')
    readSetForm()
}

// 确定
function submit() {
    let total = 0;
    for (let key in state.form) {
        total += state.form[key]
    }
    if (total !== 100) {
        ElMessage.error('合计必须等于100')
        return
    }
    emits('setStorageConfig', state.form);// 保存到本地缓存
    handleClose()
}

// 关闭监听
function handleClose() {
    emits('close')
}
</script>

<style lang="scss" scoped>
.btn_bar {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    padding-bottom: 30px;
}

.table_bar {
    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            border: 1px solid #ccc;
            text-align: center;
            padding: 5px 0;

            &.back {
                background-color: #f2f2f2;
            }

            &.text_red {
                color: red;
            }
        }

        th {
            background-color: #f2f2f2;
            color: #000;
        }
    }
}

.el-divider--horizontal {
    margin-top: 0;
}
</style>